<template>
    <CFooter>
        <div class="ms-auto">
            <span class="me-1 text-muted" target="_blank">Versión {{version}}</span>
        </div>
    </CFooter>
</template>

<script>

import {config} from "@/config";

export default {
    name: 'AppFooter',
    data() {
        return {
            version: '',
        };
    },
    mounted() {
        this.version = config.appVersion;
    },
    methods: {

    }
}
</script>
