<template>
    <CDropdown variant="nav-item">
        <CDropdownToggle placement="bottom-end" class="py-0 userMenu" :caret="false">
            <CAvatar :src="avatar" size="md"/>
        </CDropdownToggle>
        <CDropdownMenu class="pt-0">
            <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
                Operaciones
            </CDropdownHeader>
            <CDropdownItem @click="logout">
                <CIcon icon="cil-lock-locked"/>
                Cerrar sesión
            </CDropdownItem>
            <!--<CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
                Account
            </CDropdownHeader>
            <CDropdownItem>
                <CIcon icon="cil-bell"/>
                Updates
                <CBadge color="info" class="ms-auto">{{ itemsCount }}</CBadge>
            </CDropdownItem>
            <CDropdownItem>
                <CIcon icon="cil-envelope-open"/>
                Messages
                <CBadge color="success" class="ms-auto">{{ itemsCount }}</CBadge>
            </CDropdownItem>
            <CDropdownItem>
                <CIcon icon="cil-task"/>
                Tasks
                <CBadge color="danger" class="ms-auto">{{ itemsCount }}</CBadge>
            </CDropdownItem>
            <CDropdownItem>
                <CIcon icon="cil-comment-square"/>
                Comments
                <CBadge color="warning" class="ms-auto">{{ itemsCount }}</CBadge>
            </CDropdownItem>
            <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
                Settings
            </CDropdownHeader>
            <CDropdownItem>
                <CIcon icon="cil-user"/>
                Profile
            </CDropdownItem>
            <CDropdownItem>
                <CIcon icon="cil-settings"/>
                Settings
            </CDropdownItem>
            <CDropdownItem>
                <CIcon icon="cil-dollar"/>
                Payments
                <CBadge color="secondary" class="ms-auto">{{ itemsCount }}</CBadge>
            </CDropdownItem>
            <CDropdownItem>
                <CIcon icon="cil-file"/>
                Projects
                <CBadge color="primary" class="ms-auto">{{ itemsCount }}</CBadge>
            </CDropdownItem>
            <CDropdownDivider/>
            <CDropdownItem>
                <CIcon icon="cil-shield-alt"/>
                Lock Account
            </CDropdownItem>-->
            <!--<CDropdownItem>
                <CIcon icon="cil-lock-locked"/>
                Logout
            </CDropdownItem>-->
        </CDropdownMenu>
    </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/1.png'
import {mapMutations} from "vuex";
import {logo} from "@/assets/brand/logo";

export default {
    name: 'AppHeaderDropdownAccnt',
    setup() {
        return {
            avatar: avatar,
            itemsCount: 42,
        }
    },
    mounted() {
        //document.getElementsByClassName('userMenu').removeAttribute('href');
    },
    methods: {
        logo() {
            return logo
        },

    }
}
</script>
